const items = document.querySelectorAll(".Navigation a[data-image]");

const showFeaturedImage = (ev) => {
	const imageSrc = ev.currentTarget.getAttribute("data-image");

	document.querySelector(
		".Navigation__image"
	).style.backgroundImage = `url(${imageSrc})`;
};

items.forEach((item) => item.addEventListener("mouseover", showFeaturedImage));
