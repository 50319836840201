import axios from "axios";

export default class SearchForm {
	constructor(container) {
		this.container = container;
		this.form = this.container.querySelector("form");
		this.serchTerm = null;
		this.timeout = null;
		this.openBtn = document.querySelector(".open-seach-form-btn");
		this.closeBtn = document.querySelector(".close-search-form-popup");
		this.formPopup = document.querySelector(".search-form-popup");
		this.resultsContainer = this.formPopup.querySelector(
			".search-results-wrapper .wrapper"
		);
		this.pageContainer = document.getElementById("page");
		this.loader = this.formPopup.querySelector(".s-loader");
		this.nothingFound = 0;
		this.init();
	}

	init() {
		this.openBtn.addEventListener("click", (e) => {
			e.preventDefault();
			this.formPopup.classList.add("open");
			this.pageContainer.classList.add("darken");
			setTimeout(() => {
				document.querySelector(".s-input").focus();
			}, 100);
		});

		this.closeBtn.addEventListener("click", (e) => {
			e.preventDefault();
			this.formPopup.classList.remove("open");
			this.pageContainer.classList.remove("darken");
		});

		this.form.addEventListener("input", (e) => {
			this.searchTerm = e.target.value;
			clearTimeout(this.timeout);
			this.nothingFound = 0;
			if (this.searchTerm.length > 2) {
				this.timeout = setTimeout(() => {
					this.resultsContainer.innerHTML = "";
					this.showLoader();
					this.getResults();
				}, 1000);
			}
		});
	}

	getResults() {
		this.getData(
			`https://luxly.pl/wp-json/categories/v1/search?key=${this.searchTerm}`,
			"kategorie"
		);

		setTimeout(() => {
			this.getData(
				`https://luxly.pl/wp-json/products/v1/search?key=${this.searchTerm}`,
				"produkty"
			);
		}, 100);
	}

	getData(url, type) {
		const options = {
			method: "GET",
			url: url,
		};

		axios
			.request(options)
			.then((response) => {
				if (response.data.length > 0) {
					this.buildList(type, response.data);
				} else {
					this.nothingFound++;
				}
				this.nothingFoundCheck();
			})
			.catch(function(error) {
				console.error(error);
			});
	}

	buildList(type, data) {
		let sectionHeading = "";
		if (type === "kategorie") {
			sectionHeading = "Kategorie";
		} else if (type === "produkty") {
			sectionHeading = "Produkty";
		}

		const container = document.createElement("div");
		const heading = document.createElement("h4");
		container.classList.add("search-results");
		container.classList.add(type);
		heading.innerText = sectionHeading;
		container.appendChild(heading);

		data.map((item) => {
			const itemEl = document.createElement("a");

			if (type === "kategorie") {
				itemEl.innerText = item.name;
				itemEl.href = item.link;
			}

			if (type === "produkty") {
				itemEl.href = item.permalink;
				itemEl.innerHTML = `<img src='${item.images.shop_thumbnail}' />`;
				itemEl.innerHTML += `<p class="info"><span class="name">${item.name}</span><span class="sku">${item.sku}</span></p>`;
				itemEl.innerHTML += `<span class="price">${item.price}</span>`;
			}

			container.appendChild(itemEl);
		});

		this.resultsContainer.appendChild(container);
		this.hideLoader();
	}

	nothingFoundCheck() {
		if (this.nothingFound === 2) {
			this.hideLoader();
			const container = document.createElement("div");
			const heading = document.createElement("h4");
			container.classList.add("search-results");
			heading.innerText = "Brak wyników dla wpisanej frazy";
			container.appendChild(heading);
			this.resultsContainer.appendChild(container);
		}
	}

	showLoader() {
		this.loader.classList.add("show");
	}

	hideLoader() {
		this.loader.classList.remove("show");
	}
}
