const gridListSwitch = () => {
	const columns = document
		.querySelector(".grid-list-selection")
		.getAttribute("data-columns");
	const gridBtn = document.querySelector(".grid-list-selection a.grid");
	const listBtn = document.querySelector(".grid-list-selection a.list");
	const productsList = document.querySelector(".ProductsList");

	gridBtn.addEventListener("click", (e) => {
		e.preventDefault();
		productsList.classList.add(`ProductsList--columns-${columns}`);
		productsList.classList.remove("ProductsList--columns-1");
		gridBtn.classList.add("selected");
		listBtn.classList.remove("selected");
	});

	listBtn.addEventListener("click", (e) => {
		e.preventDefault();
		productsList.classList.add("ProductsList--columns-1");
		productsList.classList.remove(`ProductsList--columns-${columns}`);
		listBtn.classList.add("selected");
		gridBtn.classList.remove("selected");
	});
};

export default gridListSwitch;
