/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

export const toggleMenu = () => {
	const hamburger = document.querySelector(".Hamburger");

	hamburger.addEventListener("click", () => {
		hamburger.classList.toggle("Hamburger--open");
		document
			.querySelector(".Navigation")
			.classList.toggle("Navigation--active");
	});
};

export const closeMenu = () => {
	let links = document.querySelectorAll(".main-navigation a"),
		nav = document.getElementById("site-navigation"),
		toggle = document.getElementById("toggle");

	links.forEach((e) => {
		e.addEventListener("click", () => {
			nav.classList.remove("open");
			toggle.classList.remove("open");
		});
	});
};

export const addSticky = () => {
	let toggle = document.getElementById("toggle"),
		header = document.getElementById("masthead");

	toggle &&
		toggle.addEventListener("click", () => {
			header.classList.add("sticky");
		});
};
