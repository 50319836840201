import Swiper from "swiper";
import SwiperCore, { Autoplay, Thumbs } from "swiper/core";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay, Thumbs]);

const thumbnailSwiper = new Swiper(".products-thumbnails", {
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
	spaceBetween: 10,
	slidesPerView: 5,
});

const gallerySwiper = new Swiper(".woocommerce-product-gallery__wrapper", {
	loop: true,
	autoplay: {
		delay: 3000,
	},
	thumbs: {
		swiper: thumbnailSwiper,
	},
});
