import $ from "jquery";

var timeout;

$(".woocommerce-cart .woocommerce").on(
	"change click",
	"input.qty, .update-quantity-btn, .inner-wrap a",
	function() {
		if (timeout !== undefined) {
			clearTimeout(timeout);
		}

		timeout = setTimeout(function() {
			$("[name='update_cart']").trigger("click");
		}, 500); // 1 second delay, half a second (500) seems comfortable too
	}
);
